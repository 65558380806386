import React from 'react';
import Layout from '../utils/layout';
import SignIn from '../components/scenes/Auth/scenes/SignIn/SignIn';
import { compose } from 'recompose';
import {
  withAuthorization,
  withEmailVerification,
} from '../utils/Session';
const condition = (authUser) => !!authUser;

const SignInPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(SignIn);

export default () => (
  <Layout>
    <SignInPage />
  </Layout>
);
