import React, { Component } from 'react';
import { Link } from 'gatsby';
import { LANDING } from '../../../../../constants/routes';

import SignInForm from '../../molecules/SignInForm';
import SignInGoogle from '../../molecules/SignInGoogle';
import SignInFacebook from '../../molecules/SignInFacebook';
import SignInTwitter from '../../molecules/SignInTwitter';
import SignUpLink from '../../scenes/SignUp/atoms/SignUpLink';
import PasswordForgetLink from '../../../../molecules/PasswordForget/atoms/PasswordForgetLink';
import SignInGithub from '../../molecules/SignInGithub';
import Logo from './../../../../../assets/svg/rebus-metod-logo.svg';

class SignIn extends Component {
  render() {
    return (
      <div className="login">
        <div className="login__content">
          <div className="login__content__inner">
            <div className="login__content__header">
              <Link to={LANDING} activeClassName="active">
                <img
                  className="login__content__header__logo"
                  src={Logo}
                />
              </Link>
            </div>
            <div className="login__content__login-form">
              <div className="login__content__form">
                <SignInForm />
              </div>
              <div className="login__content__password-forget">
                <PasswordForgetLink />
              </div>
              <div className="login__content__or">
                <span className="login__content__or__line" />
                <span className="login__content__or__text">або</span>
                <span className="login__content__or__line" />
              </div>
              <div className="login__content__providers">
                <div className="login__content__providers--left">
                  <SignInGoogle />
                  <SignInGithub />
                </div>
                <div className="login__content__providers--right">
                  <SignInTwitter />
                  <SignInFacebook />
                </div>
              </div>{' '}
            </div>
            <div className="login__content__register">
              <SignUpLink isRegister />
            </div>
          </div>
        </div>
        <div className="login__image" />
      </div>
    );
  }
}

export default SignIn;
